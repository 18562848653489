<template>
    <main v-if="user" class="flex justify-center">
        <HeaderComponent />
        <section class="w-full lg:pl-[50px] lg:pt-[95px] pt-[95px] max-h-screen overflow-y-auto relative flex justify-start flex-col lg:p-[25px] p-[20px] h-auto min-h-screen bg-gray-200">
            <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[90%] max-w-[400px] flex flex-col">
                <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
                    {{ errorMessage }}
                </div>
                <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
                    {{ successMessage }}
                </div>
            </div>
            <div v-if="user.isSuper" class="w-full flex flex-col lg:flex-row lg:justify-between flex-wrap justify-center items-center relative my-[10px]">
                <div class="h-[150px] lg:w-[calc(100%/3-10px)] my-[10px] w-full bg-white rounded-[10px] p-[20px]">
                    <div class="w-full relative flex items-center justify-between">
                        <i class="fa-solid fa-circle-dollar-to-slot text-gray-500"></i>
                        <div :class="['w-[45px] h-[45px] rounded-[50%] p-[3.5px] relative', percentageChangeTotalAmount >= 0 ? 'bg-blue-100' : 'bg-red-100']">
                            <div class="bg-white flex justify-center items-center text-center text-[8px] font-bold w-full h-full rounded-[50%]">
                                {{ percentageChangeTotalAmount.toFixed(2) }}%
                            </div>
                        </div>
                    </div>
                    <h1 class="text-gray-400 font-bold text-sm">Montant total sans réduction</h1>
                    <div class="my-[5px] font-bold text-xl text-gray-700">
                        FCFA {{ totalAmount.toLocaleString() }}
                    </div>
                    <span class="text-gray-400 text-xs font-bold">Statistiques financiers mensuelles</span>
                </div>
                <div class="h-[150px] lg:w-[calc(100%/3-10px)] my-[10px] w-full bg-white rounded-[10px] p-[20px]">
                    <div class="w-full relative flex items-center justify-between">
                        <i class="fa-solid fa-hand-holding-dollar text-gray-500"></i>
                        <div :class="['w-[45px] h-[45px] rounded-[50%] p-[3.5px] relative', percentageChangeTotalRevenue >= 0 ? 'bg-blue-100' : 'bg-red-100']">
                            <div class="bg-white flex justify-center items-center text-center text-[8px] font-bold w-full h-full rounded-[50%]">
                                {{ percentageChangeTotalRevenue.toFixed(2) }}%
                            </div>
                        </div>
                    </div>
                    <h1 class="text-gray-400 font-bold text-sm">Recette total après réduction</h1>
                    <div class="my-[5px] font-bold text-xl text-gray-700">
                        FCFA {{ totalRevenue.toLocaleString() }}
                    </div>
                    <span class="text-gray-400 text-xs font-bold">Statistiques financiers mensuelles</span>
                </div>
                <div class="h-[150px] lg:w-[calc(100%/3-10px)] my-[10px] w-full bg-white rounded-[10px] p-[20px]">
                    <div class="w-full relative flex items-center justify-between">
                        <i class="fa-solid fa-hand-holding-dollar text-gray-500"></i>
                        <div :class="['w-[45px] h-[45px] rounded-[50%] p-[3.5px] relative', percentageChangeTotalRevenue >= 0 ? 'bg-blue-100' : 'bg-red-100']">
                            <div class="bg-white flex justify-center items-center text-center text-[8px] font-bold w-full h-full rounded-[50%]">
                                100%
                            </div>
                        </div>
                    </div>
                    <h1 class="text-gray-400 font-bold text-sm">Recette total après réduction</h1>
                    <div class="my-[5px] font-bold text-xl text-gray-700">
                        FCFA {{ totalRevenueAll.toLocaleString() }}
                    </div>
                    <span class="text-gray-400 text-xs font-bold">Statistiques financiers complètes</span>
                </div>
            </div>
            <div v-if="user.isSuper" class="w-full flex flex-col md:flex-row flex-wrap justify-center items-center relative my-[10px]">
                <div class="py-[10px] w-full h-auto relative rounded-[10px] bg-white flex justify-center items-center flex-wrap">
                    <div class="md:w-[calc(100%/5-24px)] w-[calc(100%-24px)] h-[135px] m-[10px] cursor-pointer bg-gray-600 text-white rounded-[10px] p-[10px] flex flex-col justify-center items-center">
                        <i class="fa-solid fa-layer-group"></i>
                        <h1 class="lg:text-sm text-xs font-bold text-center my-[5px]">Stock Général</h1>
                        <span class="text-gray-200 font-semibold text-xl text-center">{{ getTotalFilteredProduits().totalGeneralQuantite }} Articles</span>
                        <span class="text-gray-200 font-semibold text-xs text-center">Montant: {{ getTotalFilteredProduits().totalGeneralPrixReduit }} FCFA</span>
                    </div>
                    <div class="md:w-[calc(100%/5-24px)] w-[calc(100%/2-24px)] md:min-w-[calc(100%/5-24px)] min-w-[calc(100%/2-24px)] h-[135px] m-[10px] cursor-pointer bg-gray-600 text-white rounded-[10px] p-[10px] flex flex-col justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M20 6H4V4h16zm-7 9.5c0 1.14.43 2.36 1 3.5v1H4v-6H3v-2l1-5h16l.7 3.5c-.7-.32-1.42-.5-2.2-.5c-3 0-5.5 2.5-5.5 5.5M12 14H6v4h6zm10 1.5c0 2.6-3.5 6.5-3.5 6.5S15 18.1 15 15.5c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5m-2.3.1c0-.6-.6-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2s1.3-.6 1.2-1.2"/></svg>
                        <h1 class="lg:text-sm text-xs font-bold text-center my-[5px]">Stock Cotonou</h1>
                        <span class="text-gray-200 font-semibold text-xl text-center">{{ getStockTotal('Stock Cotonou').totalQuantite }} Articles</span>
                        <span class="text-gray-200 font-semibold text-xs text-center">Montant: {{ getStockTotal('Stock Cotonou').totalPrixReduit }} FCFA</span>
                    </div>
                    <div class="md:w-[calc(100%/5-24px)] w-[calc(100%/2-24px)] md:min-w-[calc(100%/5-24px)] min-w-[calc(100%/2-24px)] h-[135px] m-[10px] cursor-pointer bg-gray-600 text-white rounded-[10px] p-[10px] flex flex-col justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M20 6H4V4h16zm-7 9.5c0 1.14.43 2.36 1 3.5v1H4v-6H3v-2l1-5h16l.7 3.5c-.7-.32-1.42-.5-2.2-.5c-3 0-5.5 2.5-5.5 5.5M12 14H6v4h6zm10 1.5c0 2.6-3.5 6.5-3.5 6.5S15 18.1 15 15.5c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5m-2.3.1c0-.6-.6-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2s1.3-.6 1.2-1.2"/></svg>
                        <h1 class="lg:text-sm text-xs font-bold text-center my-[5px]">Stock Parakou</h1>
                        <span class="text-gray-200 font-semibold text-xl text-center">{{ getStockTotal('Stock Parakou').totalQuantite }} Articles</span>
                        <span class="text-gray-200 font-semibold text-xs text-center">Montant: {{ getStockTotal('Stock Parakou').totalPrixReduit }} FCFA</span>
                    </div>
                    <div class="md:w-[calc(100%/5-24px)] w-[calc(100%/2-24px)] md:min-w-[calc(100%/5-24px)] min-w-[calc(100%/2-24px)] h-[135px] m-[10px] cursor-pointer bg-gray-600 text-white rounded-[10px] p-[10px] flex flex-col justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M2 19V8.35q0-.625.338-1.125T3.25 6.5l8-3.2q.35-.15.75-.15t.75.15l8 3.2q.575.225.913.725T22 8.35V19q0 .825-.587 1.413T20 21h-4v-8H8v8H4q-.825 0-1.412-.587T2 19m7 2v-2h2v2zm2-3v-2h2v2zm2 3v-2h2v2z"/></svg>
                        <h1 class="lg:text-sm text-xs font-bold text-center my-[5px]">Magasin Cotonou</h1>
                        <span class="text-gray-200 font-semibold text-xl text-center">{{ getStockTotal('Magasin Cotonou').totalQuantite }} Articles</span>
                        <span class="text-gray-200 font-semibold text-xs text-center">Montant: {{ getStockTotal('Magasin Cotonou').totalPrixReduit }} FCFA</span>
                    </div>
                    <div class="md:w-[calc(100%/5-24px)] w-[calc(100%/2-24px)] md:min-w-[calc(100%/5-24px)] min-w-[calc(100%/2-24px)] h-[135px] m-[10px] cursor-pointer bg-gray-600 text-white rounded-[10px] p-[10px] flex flex-col justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M2 19V8.35q0-.625.338-1.125T3.25 6.5l8-3.2q.35-.15.75-.15t.75.15l8 3.2q.575.225.913.725T22 8.35V19q0 .825-.587 1.413T20 21h-4v-8H8v8H4q-.825 0-1.412-.587T2 19m7 2v-2h2v2zm2-3v-2h2v2zm2 3v-2h2v2z"/></svg>
                        <h1 class="lg:text-sm text-xs font-bold text-center my-[5px]">Magasin Parakou</h1>
                        <span class="text-gray-200 font-semibold text-xl text-center">{{ getStockTotal('Magasin Parakou').totalQuantite }} Articles</span>
                        <span class="text-gray-200 font-semibold text-xs text-center">Montant: {{ getStockTotal('Magasin Parakou').totalPrixReduit }} FCFA</span>
                    </div>
                </div>
            </div>
            <div v-if="user.isSuper" class="w-full flex flex-col lg:flex-row md:justify-between justify-center items-center relative">
                <div class="lg:w-[50%] w-full flex justify-between relative items-center flex-wrap">
                    <div class="w-[calc(50%-10px)] relative md:p-[20px] md:py-[10px] p-[10px] h-[120px] rounded-[10px] bg-white my-[10px] flex flex-col justify-between">
                        <div class="w-full flex justify-between items-center flex-wrap">
                            <h1 class="text-xs font-bold">Commandes</h1>
                            <i class="fa-solid fa-cart-shopping text-xl text-gray-500"></i>
                        </div>
                        <div class="my-[5px] text-3xl font-semibold">
                            {{ totalOrders }}
                        </div>
                        <div class="w-full flex items-center text-xs">
                            <div :class="['flex items-center font-bold mr-[10px]', percentageChangeTotalOrders >= 0 ? 'text-green-600' : 'text-red-600']">
                                <i :class="percentageChangeTotalOrders >= 0 ? 'fa-solid fa-arrow-up' : 'fa-solid fa-arrow-down'"></i>
                                <span class="ml-[2px]">{{ percentageChangeTotalOrders.toFixed(2) }}%</span>
                            </div>
                            <span class="text-gray-500 font-bold">{{ dayjs().format('DD/MM/YYYY') }}</span>
                        </div>
                    </div>
                    <div class="w-[calc(50%-10px)] relative md:p-[20px] md:py-[10px] p-[10px] h-[120px] rounded-[10px] bg-white my-[10px] flex flex-col justify-between">
                        <div class="w-full flex justify-between items-center flex-wrap">
                            <h1 class="text-xs font-bold">Boutique</h1>
                            <i class="fa-solid fa-shop text-xl text-gray-500"></i>
                        </div>
                        <div class="my-[5px] text-3xl font-semibold">
                            {{ boutiqueOrders }}
                        </div>
                        <div class="w-full flex items-center text-xs">
                            <div :class="['flex items-center font-bold mr-[10px]', percentageChangeBoutiqueOrders >= 0 ? 'text-green-600' : 'text-red-600']">
                                <i :class="percentageChangeBoutiqueOrders >= 0 ? 'fa-solid fa-arrow-up' : 'fa-solid fa-arrow-down'"></i>
                                <span class="ml-[2px]">{{ percentageChangeBoutiqueOrders.toFixed(2) }}%</span>
                            </div>
                            <span class="text-gray-500 font-bold">{{ dayjs().format('DD/MM/YYYY') }}</span>
                        </div>
                    </div>
                    <div class="w-[calc(50%-10px)] relative md:p-[20px] md:py-[10px] p-[10px] h-[120px] rounded-[10px] bg-white my-[10px] flex flex-col justify-between">
                        <div class="w-full flex justify-between items-center flex-wrap">
                            <h1 class="text-xs font-bold">En ligne</h1>
                            <i class="fa-solid fa-globe text-xl text-gray-500"></i>
                        </div>
                        <div class="my-[5px] text-3xl font-semibold">
                            {{ onlineOrders }}
                        </div>
                        <div class="w-full flex items-center text-xs">
                            <div :class="['flex items-center font-bold mr-[10px]', percentageChangeOnlineOrders >= 0 ? 'text-green-600' : 'text-red-600']">
                                <i :class="percentageChangeOnlineOrders >= 0 ? 'fa-solid fa-arrow-up' : 'fa-solid fa-arrow-down'"></i>
                                <span class="ml-[2px]">{{ percentageChangeOnlineOrders.toFixed(2) }}%</span>
                            </div>
                            <span class="text-gray-500 font-bold">{{ dayjs().format('DD/MM/YYYY') }}</span>
                        </div>
                    </div>
                    <div class="w-[calc(50%-10px)] relative md:p-[20px] md:py-[10px] p-[10px] h-[120px] rounded-[10px] bg-white my-[10px] flex flex-col justify-between">
                        <div class="w-full flex justify-between items-center flex-wrap">
                            <h1 class="text-xs font-bold">Réductions</h1>
                            <i class="fa-solid fa-hand-holding-dollar text-xl text-gray-500"></i>
                        </div>
                        <div class="my-[5px] text-3xl font-semibold">
                           - {{ percentageReduction.toFixed(2) }}%
                        </div>
                        <div class="w-full flex items-center text-xs">
                            <div :class="['flex items-center font-bold mr-[10px]', percentageChangeRevenue >= 0 ? 'text-green-600' : 'text-red-600']">
                                <i :class="percentageChangeRevenue >= 0 ? 'fa-solid fa-arrow-up' : 'fa-solid fa-arrow-down'"></i>
                                <span class="ml-[2px]">{{ percentageChangeRevenue.toFixed(2) }}%</span>
                            </div>
                            <span class="text-gray-500 font-bold">{{ dayjs().format('DD/MM/YYYY') }}</span>
                        </div>
                    </div>
                </div>
                <div class="lg:w-[50%] w-full flex md:justify-end justify-start relative items-center">
                    <div class="w-[calc(100%)] lg:w-[calc(100%-20px)] parent overflow-x-auto relative p-[20px] lg:h-[260px] h-[350px] flex items-center my-[10px] bg-white rounded-[10px] ">
                        <div class="chart-container">
                            <canvas id="myChart" ref="myChart"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="user.isSuper" class="w-full flex flex-col lg:flex-row md:justify-between justify-center items-center relative">
                <div class="w-full flex md:justify-end justify-start relative items-center">
                    <div class="w-[calc(100%)] relative p-[20px] parent overflow-x-auto lg:h-[260px] h-[350px] flex items-center my-[10px] bg-white rounded-[10px] ">
                        <div class="chart-container1">
                            <canvas id="myChart1" ref="myChart1"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full flex flex-col lg:flex-row justify-center items-center relative my-[10px]">
                <div id="parent" class="rounded-[10px] my-[10px] bg-white w-full min-h-[350px] overflow-y-auto">
                    <h1 class="text-gray-600 font-bold my-[10px] px-[20px]">Historique des ventes</h1>
                    <ul>
                        <li class="w-full relative flex p-[10px] bg-gray-100">
                            <div class="border-l-[0.5px] uppercase text-sm text-gray-500 font-semibold border-r-[0.5px] hidden md:flex w-[10%] md:w-[5%] border-gray-400 py-[5px] p-[10px]">ID</div>
                            <div class="border-l-[0.5px] uppercase text-sm text-gray-500 font-semibold border-r-[0.5px] w-[60%] md:w-[30%] border-gray-400 py-[5px] p-[10px]">NOM</div>
                            <div class="border-l-[0.5px] uppercase text-sm text-gray-500 font-semibold border-r-[0.5px] hidden md:flex md:w-[20%] border-gray-400 py-[5px] p-[10px]">NUMERO</div>
                            <div class="border-l-[0.5px] uppercase text-sm text-gray-500 font-semibold border-r-[0.5px] hidden md:flex md:w-[20%] border-gray-400 py-[5px] p-[10px]">VENDEUR</div>
                            <div class="border-l-[0.5px] uppercase text-sm text-gray-500 font-semibold border-r-[0.5px] w-[40%] md:w-[25%] border-gray-400 py-[5px] p-[10px]">PRODUITS</div>
                        </li>
                        <li v-for="(vente, index) in salesToday" :key="index" :id="(index + 1)" class="w-full relative flex p-[10px] bg-white border-b-[0.5px] border-gray-400">
                            <div class="border-l-[0.5px] text-sm text-gray-500 font-semibold border-r-[0.5px] hidden md:flex w-[10%] md:w-[5%] border-gray-400 py-[5px] p-[10px]">#{{ index + 1 }}</div>
                            <div class="border-l-[0.5px] text-sm text-gray-500 font-semibold border-r-[0.5px] w-[60%] md:w-[30%] border-gray-400 py-[5px] p-[10px]">
                                {{ vente.client ? vente.client.nom : 'Nom non disponible' }} {{ vente.client ? vente.client.prenoms : 'Prénoms non disponibles' }}
                            </div>
                            <div class="border-l-[0.5px] text-sm text-gray-500 font-semibold border-r-[0.5px] hidden md:flex md:w-[20%] border-gray-400 py-[5px] p-[10px]">
                                {{ vente.client ? vente.client.numero : 'Numéro non disponible' }}
                            </div>
                            <div class="border-l-[0.5px] text-sm text-gray-500 font-semibold border-r-[0.5px] hidden md:flex md:w-[20%] border-gray-400 py-[5px] p-[10px]">
                                {{ vente.vendeur ? vente.vendeur.nom : 'Nom non disponible' }} {{ vente.vendeur ? vente.vendeur.prenoms : 'Prénoms non disponibles' }}
                            </div>
                            <div class="border-l-[0.5px] text-sm text-gray-500 font-semibold border-r-[0.5px] w-[40%] md:w-[25%] border-gray-400 py-[5px] p-[10px]">
                                {{ vente.produits.map((p) => `${p.lotName} (${p.quantity})`).join(', ') }}
                            </div>
                        </li>
                        <li class="w-full h-[150px] flex justify-center items-center text-center text-gray-800 font-bold text-sm" v-if="salesToday.length == 0">
                            Aucune vente enregistrée aujourd'hui !
                        </li>
                    </ul>
                    <li class="w-full bg-gray-100 flex justify-start items-center text-center p-[10px] text-gray-500 text-sm font-semibold uppercase">
                        <p>Total : <span class="text-xl">{{ totalSalesToday }} FCFA</span></p>
                    </li>
                </div>
            </div>
        </section>
    </main>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';
import HeaderComponent from '@/components/HeaderComponent.vue';
import { useAuthStore } from '@/store/auth';
import { Line } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler);

Chart.register(...registerables);

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const produits = ref([])
const myChart = ref(null);
const myChart1 = ref(null);
const errorMessage = ref('')
const successMessage = ref('')

const filteredSales = ref([]);

const fetchProduits = async () => {
  try {
    const response = await axios.get('/api/lots');
    produits.value = response.data.flatMap(lot => lot.produits);
  } catch (error) {
    errorMessage.value = `Erreur lors du chargement des produits : ${error.response ? error.response.data.message : 'Vérifiez votre connexion internet'}`;
    hideErrorMessageAfterDelay();
  }
};

const getStockTotal = (stockName) => {
  const produitsForStock = produits.value.filter(produit => produit.stock === stockName);
  
  // Calcul de la quantité totale et du montant total avec prix réduit
  const totalQuantite = produitsForStock.reduce((acc, produit) => acc + produit.quantite, 0);
  //const totalPrixReduit = produitsForStock.reduce((acc, produit) => acc + (Number(produit.prix_reduit) || 0), 0);
  const totalPrixReduit = produitsForStock.reduce((acc, produit) => 
    acc + (produit.quantite > 0 ? (Number(produit.prix_reduit) || 0) * produit.quantite : 0), 0
  );
  
  console.log(`Total pour ${stockName} - Quantité: ${totalQuantite}, Montant Prix Réduit: ${totalPrixReduit}`); 
  return { totalQuantite, totalPrixReduit };
};

const getTotalFilteredProduits = () => {
  // Inclure seulement les produits des quatre autres stocks pour le Stock Général
  const filteredProduitsForGeneral = produits.value.filter(produit => 
    ['Stock Parakou', 'Stock Cotonou', 'Magasin Parakou', 'Magasin Cotonou'].includes(produit.stock)
  );

  // Calculer le total des quantités et des prix réduits pour Stock Général
  const totalGeneralQuantite = filteredProduitsForGeneral.reduce((acc, produit) => acc + produit.quantite, 0);
  //const totalGeneralPrixReduit = filteredProduitsForGeneral.reduce((acc, produit) => acc + (produit.prix_reduit || 0), 0);
  const totalGeneralPrixReduit = filteredProduitsForGeneral.reduce((acc, produit) => 
    acc + (produit.quantite > 0 ? (produit.prix_reduit || 0) * produit.quantite : 0), 0
  );

  console.log(`Total Stock Général - Quantité: ${totalGeneralQuantite}, Montant Prix Réduit: ${totalGeneralPrixReduit}`); // Log pour vérifier les calculs
  return { totalGeneralQuantite, totalGeneralPrixReduit };
};

const totalOrders = ref(0);
const boutiqueOrders = ref(0);
const onlineOrders = ref(0);
const totalAmount = ref(0);
const totalRevenue = ref(0);
const totalRevenueAll = ref(0)
const percentageReduction = ref(0);
const percentageChangeTotalOrders = ref(0);
const percentageChangeBoutiqueOrders = ref(0);
const percentageChangeOnlineOrders = ref(0);
const percentageChangeTotalAmount = ref(0);
const percentageChangeTotalRevenue = ref(0);
const percentageChangeRevenue = ref(0);
const salesToday = ref([])

const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return current === 0 ? 0 : 100;
    return ((current - previous) / Math.abs(previous)) * 100;
};

async function fetchSalesbyVendeur() {
  try {
    let url = '/api/sales/vendeur';
    
    if (user.value.lastname || user.value.lastname) {
      url = `/api/sales/vendeur?nom=${user.value.lastname}&prenoms=${user.value.firstname}`;
    }

    const { data } = await axios.get(url);
    const today = dayjs().startOf('day');
    const todayData = data.filter(sale => dayjs(sale.createdAt).isSame(today, 'day'));
    salesToday.value = todayData;
  } catch (error) {
    errorMessage.value = "Erreur lors de la récupération des ventes"
    hideErrorMessageAfterDelay()
  }
}


const fetchSalesData = async () => {
    try {
        const { data } = await axios.get('/api/validated-sales/');
        const currentMonth = dayjs().month();
        const previousMonth = dayjs().subtract(1, 'month').month();

        const currentMonthData = data.filter(sale => dayjs(sale.createdAt).month() === currentMonth);
        const previousMonthData = data.filter(sale => dayjs(sale.createdAt).month() === previousMonth);

        const preparedSales = data.map(sale => ({
          ...sale,
          boutiqueType: sale.vendeur.boutique === 'En ligne' ? 'En ligne' : 'Autres'
        }));

        filteredSales.value = data;

        totalOrders.value = currentMonthData.length;
        boutiqueOrders.value = currentMonthData.filter(sale => sale.vendeur.boutique !== 'En ligne').length;
        onlineOrders.value = currentMonthData.filter(sale => sale.vendeur.boutique === 'En ligne').length;

        totalAmount.value = currentMonthData.reduce((acc, sale) => acc + sale.total, 0);
        totalRevenue.value = currentMonthData.reduce((acc, sale) => acc + sale.totalApresReduction, 0);
        totalRevenueAll.value = data.reduce((acc, sale) => acc + sale.totalApresReduction, 0);

        const previousTotalOrders = previousMonthData.length;
        const previousBoutiqueOrders = previousMonthData.filter(sale => sale.vendeur.boutique !== 'En ligne').length;
        const previousOnlineOrders = previousMonthData.filter(sale => sale.vendeur.boutique === 'En ligne').length;

        const previousTotalAmount = previousMonthData.reduce((acc, sale) => acc + sale.total, 0);
        const previousTotalRevenue = previousMonthData.reduce((acc, sale) => acc + sale.totalApresReduction, 0);

        percentageChangeTotalOrders.value = calculatePercentageChange(totalOrders.value, previousTotalOrders);
        percentageChangeBoutiqueOrders.value = calculatePercentageChange(boutiqueOrders.value, previousBoutiqueOrders);
        percentageChangeOnlineOrders.value = calculatePercentageChange(onlineOrders.value, previousOnlineOrders);

        percentageChangeTotalAmount.value = calculatePercentageChange(totalAmount.value, previousTotalAmount);
        percentageChangeTotalRevenue.value = calculatePercentageChange(totalRevenue.value, previousTotalRevenue);

        const totalReductionCurrentMonth = currentMonthData.reduce((acc, sale) => acc + sale.reductionMontant, 0);
        const totalReductionPreviousMonth = previousMonthData.reduce((acc, sale) => acc + sale.reductionMontant, 0);
        percentageReduction.value = (totalReductionCurrentMonth / totalAmount.value) * 100;
        percentageChangeRevenue.value = calculatePercentageChange(totalReductionCurrentMonth, totalReductionPreviousMonth);
    } catch (error) {
        errorMessage.value = "Erreur lors de la récupération des ventes"
        hideErrorMessageAfterDelay()
    }
};
const totalSalesToday = ref(0);

const fetchSalesDataToday = async () => {
    try {
        const { data } = await axios.get('/api/sales/');
        const today = dayjs().startOf('day');

        // Filtrer les ventes de la journée en cours
        const todayData = data.filter(sale => dayjs(sale.createdAt).isSame(today, 'day'));

        // Mettre à jour les ventes d'aujourd'hui
        salesToday.value = todayData;

        // Calculer le montant total des ventes d'aujourd'hui
        totalSalesToday.value = todayData.reduce((acc, sale) => acc + (sale.totalApresReduction || 0), 0);

    } catch (error) {
        errorMessage.value = "Erreur lors de la récupération des ventes";
        hideErrorMessageAfterDelay();
    }
};

let chart;
let chart1;

const createChart = () => {
  const ctx = document.getElementById('myChart').getContext('2d');

  // Détruire le graphique précédent s'il existe
  if (chart) {
    chart.destroy();
  }

  // Initialiser un dictionnaire pour les données regroupées par mois et année
  const onlineData = {};
  const CotonouData = {};
  const ParakouData = {};

  // Récupérer les ventes et les grouper par mois et année
  filteredSales.value.forEach(sale => {
    const date = new Date(sale.createdAt);
    const year = date.getFullYear();
    const month = date.getMonth(); // Index du mois (0 = Janvier)
    const monthYear = `${year}-${String(month + 1).padStart(2, '0')}`; // Format: "YYYY-MM"

    if (!onlineData[monthYear]) onlineData[monthYear] = 0;
    if (!CotonouData[monthYear]) CotonouData[monthYear] = 0;
    if (!ParakouData[monthYear]) ParakouData[monthYear] = 0;

    if (sale.vendeur.boutique === 'En ligne') {
      onlineData[monthYear] += 1;
    } else if (sale.vendeur.boutique === 'Cotonou') {
      CotonouData[monthYear] += 1;
    } else {
      ParakouData[monthYear] += 1;
    }
  });

  // Extraire et trier les labels (mois et années)
  const labels = Object.keys(onlineData)
    .concat(Object.keys(CotonouData), Object.keys(ParakouData))
    .filter((value, index, self) => self.indexOf(value) === index) // Supprimer les doublons
    .sort((a, b) => new Date(a) - new Date(b)); // Trier par date

  // Convertir les labels dans un format lisible ("MMM YYYY")
  const readableLabels = labels.map(label => {
    const [year, month] = label.split('-');
    return new Date(year, month - 1).toLocaleString('default', { month: 'short', year: 'numeric' });
  });

  // Construire les datasets en alignant les données avec les labels triés
  const onlineDataset = labels.map(label => onlineData[label] || 0);
  const CotonouDataset = labels.map(label => CotonouData[label] || 0);
  const ParakouDataset = labels.map(label => ParakouData[label] || 0);

  // Créer le graphique
  chart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: readableLabels, // Utiliser les labels lisibles triés
      datasets: [
        {
          label: 'En ligne',
          borderColor: '#36A2EB',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          pointBackgroundColor: 'rgba(54, 162, 235, 1)',
          pointBorderColor: '#fff',
          data: onlineDataset,
          fill: true,
          tension: 0.4,
        },
        {
          label: 'Cotonou',
          borderColor: '#FF6384',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          pointBackgroundColor: 'rgba(255, 99, 132, 1)',
          pointBorderColor: '#fff',
          data: CotonouDataset,
          fill: true,
          tension: 0.4,
        },
        {
          label: 'Parakou',
          borderColor: '#4CAF50',
          backgroundColor: 'rgba(76, 175, 80, 0.2)',
          pointBackgroundColor: 'rgba(76, 175, 80, 1)',
          pointBorderColor: '#fff',
          data: ParakouDataset,
          fill: true,
          tension: 0.4,
        }
      ]
    },
    options: {
      responsive: true, // S'adapte à l'écran pour une meilleure expérience mobile
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          callbacks: {
            title: (tooltipItems) => tooltipItems[0].label, // Afficher le label complet
          }
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Mois',
          }
        },
        y: {
          title: {
            display: true,
            text: 'Nombre de ventes',
          },
          beginAtZero: true,
        }
      }
    }
  });
};



const createChart1 = () => {
  const ctx = document.getElementById('myChart1').getContext('2d');

  // Détruire le graphique précédent s'il existe
  if (chart1) {
    chart1.destroy();
  }

  // Initialiser un dictionnaire pour les données regroupées par mois et année
  const onlineData = {};
  const CotonouData = {};
  const ParakouData = {};

  // Récupérer les ventes et les grouper par mois et année
  filteredSales.value.forEach(sale => {
    const date = new Date(sale.createdAt);
    const year = date.getFullYear();
    const month = date.getMonth(); // Index du mois (0 = Janvier)
    const monthYear = `${year}-${String(month + 1).padStart(2, '0')}`; // Format: "YYYY-MM"

    if (!onlineData[monthYear]) onlineData[monthYear] = 0;
    if (!CotonouData[monthYear]) CotonouData[monthYear] = 0;
    if (!ParakouData[monthYear]) ParakouData[monthYear] = 0;

    if (sale.vendeur.boutique === 'En ligne') {
      onlineData[monthYear] += sale.totalApresReduction;
    } else if (sale.vendeur.boutique === 'Cotonou') {
      CotonouData[monthYear] += sale.totalApresReduction;
    } else {
      ParakouData[monthYear] += sale.totalApresReduction;
    }
  });

  // Extraire et trier les labels (mois et années)
  const labels = Object.keys(onlineData)
    .concat(Object.keys(CotonouData), Object.keys(ParakouData))
    .filter((value, index, self) => self.indexOf(value) === index) // Supprimer les doublons
    .sort((a, b) => new Date(a) - new Date(b)); // Trier par date

  // Convertir les labels dans un format lisible ("MMM YYYY")
  const readableLabels = labels.map(label => {
    const [year, month] = label.split('-');
    return new Date(year, month - 1).toLocaleString('default', { month: 'short', year: 'numeric' });
  });

  // Construire les datasets en alignant les données avec les labels triés
  const onlineDataset = labels.map(label => onlineData[label] || 0);
  const CotonouDataset = labels.map(label => CotonouData[label] || 0);
  const ParakouDataset = labels.map(label => ParakouData[label] || 0);

  // Créer le graphique
  chart1 = new Chart(ctx, {
    type: 'line',
    data: {
      labels: readableLabels, // Utiliser les labels lisibles triés
      datasets: [
        {
          label: 'En ligne',
          borderColor: '#36A2EB',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          pointBackgroundColor: 'rgba(54, 162, 235, 1)',
          pointBorderColor: '#fff',
          data: onlineDataset,
          fill: true,
          tension: 0.4,
        },
        {
          label: 'Cotonou',
          borderColor: '#FF6384',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          pointBackgroundColor: 'rgba(255, 99, 132, 1)',
          pointBorderColor: '#fff',
          data: CotonouDataset,
          fill: true,
          tension: 0.4,
        },
        {
          label: 'Parakou',
          borderColor: '#4CAF50',
          backgroundColor: 'rgba(76, 175, 80, 0.2)',
          pointBackgroundColor: 'rgba(76, 175, 80, 1)',
          pointBorderColor: '#fff',
          data: ParakouDataset,
          fill: true,
          tension: 0.4,
        }
      ]
    },
    options: {
      responsive: true, // S'adapte à l'écran pour une meilleure expérience mobile
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          callbacks: {
            title: (tooltipItems) => tooltipItems[0].label, // Afficher le label complet
          }
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Mois',
          }
        },
        y: {
          title: {
            display: true,
            text: 'Montant total (après réduction)',
          },
          beginAtZero: true,
        }
      }
    }
  });
};

onMounted(async () => {
    if (user.value.isSuper) {
        await fetchSalesData()
        await fetchSalesDataToday()
    } else {
        await fetchSalesbyVendeur()
    }
    fetchProduits()
    if (myChart.value && myChart1.value) {
        createChart()
        createChart1()
    }
});

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 5000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 10000);
};

</script>
<style scoped>
.savoir {
    opacity: 0;
}

#hover:hover .savoir {
    opacity: 1;
}
.shadow-6xl {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px, rgba(0, 0, 0, 0.2) 0px 6px 6px;
}
.chart-container {
  position: relative;
  min-width: 500px;
  min-height: 300px;
}
.chart-container1 {
  position: relative;
  min-width: 500px;
  min-height: 300px;
}
</style>