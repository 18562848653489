<template>
    <main v-if="user" class="flex justify-center">
        <HeaderComponent/>
        <section class="w-full lg:pl-[50px] lg:pt-[95px] pt-[95px] max-h-screen overflow-y-auto relative flex justify-start flex-col lg:p-[25px] p-[20px] h-auto min-h-screen bg-gray-200">
          <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[90%] max-w-[400px] flex flex-col">
              <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
                  {{ errorMessage }}
              </div>
              <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
                  {{ successMessage }}
              </div>
          </div>
            <div class="w-full flex flex-col lg:flex-row justify-center items-center relative">
                <div class="w-full flex justify-center items-start flex-col lg:flex-row relative">
                    <div v-if="achat" class="lg:w-8/12 w-full lg:mr-[10px] mb-[10px] lg:mb-0 bg-white relative shadow-4xl rounded-t-[10px] pb-[70px] p-[10px]">
                        <ul class="w-full flex flex-col relative">
                            <li class="w-full relative flex justify-center border-b-[0.5px] border-gray-400 items-center py-[10px]">
                                <div class="lg:w-[calc(100%-225px)] w-[calc(100%-150px)] p-[5px] text-gray-800 font-bold text-sm">
                                    Produits
                                </div>
                                <div class="w-[75px] p-[5px] text-gray-800 font-bold text-sm">
                                    Prix
                                </div>
                                <div class="w-[75px] p-[5px] text-gray-800 font-bold text-sm">
                                    Quantité
                                </div>
                                <div class="w-[75px] hidden lg:flex p-[5px] text-gray-800 font-bold text-sm">
                                    Stotal
                                </div>
                            </li>
                            <li v-for="(produit, index) in produits" :key="index" class="w-full relative flex justify-center items-center py-[10px]">
                                <div class="lg:w-[calc(100%-225px)] w-[calc(100%-150px)] p-[5px] text-gray-800 font-bold text-sm flex items-center">
                                    <div class="w-[40px] h-[40px] flex justify-center items-center relative">
                                        <img :src="produit.lotPhoto" alt="" class="w-full h-full absolute object-cover">
                                    </div>
                                    <div class="ml-[5px]">
                                      <h1 class="text-sm font-semibold text-gray-600">{{ produit.lotName }} | {{ produit.stock }}</h1>
                                      <p v-if="produit.capacities && produit.colors" class="text-xs font-bold text-gray-800"><span v-if="produit.capacities[0]">{{ produit.capacities[0] }}</span> <span v-if="produit.colors[0]"> - {{ produit.colors[0] }}</span></p>
                                    </div>
                                </div>
                                <div class="w-[75px] p-[5px] text-gray-500 font-bold text-sm">
                                    {{ produit.prix_reduit }}
                                </div>
                                <div class="w-[75px] p-[5px] text-gray-800 font-bold text-sm flex justify-center items-center">
                                    <div @click="augmenterQuantite(index)" class="w-[20px] h-[20px] cursor-pointer min-w-[20px] min-h-[20px] flex justify-center items-center border ">
                                        <i class="fa-solid fa-add text-xs font-bold"></i>
                                    </div>
                                    <div class="h-[20px] text-xs w-full flex justify-center items-center">{{ produit.quantity }}</div>
                                    <div @click="diminuerQuantite(index)" class="w-[20px] h-[20px] cursor-pointer min-w-[20px] min-h-[20px] flex justify-center items-center border ">
                                        <i class="fa-solid fa-minus text-xs font-bold"></i>
                                    </div>
                                </div>
                                <div class="w-[75px] hidden lg:flex p-[5px] text-gray-500 font-bold text-sm">
                                    {{ produit.stotal }}
                                </div>
                            </li>
                            <div v-if="produits.length === 0" class="w-full text-sm font-bold text-gray-500 relative p-[30px] flex justify-center items-center text-center">
                                Aucun produit sélectionné
                            </div>
                        </ul>
                        <div class="absolute w-full bottom-0 left-0 p-[10px] border-t-[0.5px] border-gray-400 flex justify-center items-center">
                            <input v-model="recherche" type="text" placeholder="Recherchez un produit" class="w-full p-[10px] rounded-[5px] text-sm bg-gray-100 outline-none">
                            <button @click="afficherListeProduits" class="text-sm font-bold text-center p-[10px] text-white bg-orange-600 rounded-[5px] flex justify-center items-center md:w-[210px] w-[100px] ml-[10px]">Ajouter <span class="hidden md:flex md:ml-[5px]"> un produit</span></button>
                            
                        <div id="parent" v-if="afficherProduits" class="absolute top-[70px] left-0 w-full max-h-[200px] overflow-y-auto  bg-white shadow-lg z-[100]">
                            <ul>
                                <li v-for="produit in produitsFiltres" :key="produit.id" @click="ajouterProduit(produit)" class="p-[10px] border-b cursor-pointer hover:bg-gray-200">
                                    <div class="flex items-center">
                                        <div class="w-[40px] h-[40px] flex justify-center items-center relative">
                                            <img :src="produit.lotPhoto" alt="" class="w-full h-full absolute object-cover">
                                        </div>
                                        <div class="ml-[5px]">
                                          <h1 class="text-sm font-semibold text-gray-600">{{ produit.lotName }}</h1>
                                          <p v-if="produit.capacities && produit.colors" class="text-xs font-bold text-gray-800"><span v-if="produit.capacities[0]">{{ produit.capacities[0] }}</span> <span v-if="produit.colors[0]"> - {{ produit.colors[0] }}</span> <span v-if="produit.imei1"> - Imei1({{ produit.imei1 }})</span></p>
                                        </div>
                                    </div>
                                    <div class="text-sm font-bold text-gray-500">{{ produit.prix_reduit }} FCFA - {{ produit.stock }}</div>
                                </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div v-if="information" class="lg:w-8/12 w-full lg:mr-[10px] mb-[10px] lg:mb-0 bg-white relative shadow-4xl rounded-t-[10px] pb-[70px] p-[10px]">
                        <div class="w-full relative flex flex-col pb-[20px] border-b-[0.5px] border-gray-400">
                            <h1 class="text-gray-400 font-bold mb-[20px] md:text-lg text-sm">
                                Information sur le vendeur
                            </h1>
                            <div class="w-full relative flex justify-between items-center md:flex-row flex-col">
                                <div class="my-[10px] md:w-[48%] w-full relative">
                                    <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Nom du(de la) vendeur(se)</label>
                                    <input type="text" readonly v-model="fullname" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                                </div>
                                <div class="my-[10px] md:w-[48%] w-full relative">
                                    <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Poste</label>
                                    <input type="text" readonly v-model="user.poste" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                                </div>
                            </div>
                            <div class="my-[10px] w-full relative">
                                <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Boutique</label>
                                <input type="text" readonly v-model="user.boutique" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                            </div>
                            <div class="w-full relative flex justify-between items-center md:flex-row flex-col">
                                <div class="my-[10px] md:w-[48%] w-full relative">
                                    <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Numéro</label>
                                    <input type="text" readonly v-model="user.phone" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                                </div>
                                <div class="my-[10px] md:w-[48%] w-full relative">
                                    <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Email</label>
                                    <input type="text" readonly v-model="user.email" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                                </div>
                            </div>
                        </div>
                        <div class="w-full relative flex flex-col py-[20px] border-b-[0.5px] border-gray-400">
                            <h1 class="text-gray-400 font-bold mb-[20px] md:text-lg text-sm">
                                Informations du Client
                            </h1>
                            <div class="w-full relative flex justify-between items-center md:flex-row flex-col">
                                <div class="my-[10px] md:w-[48%] w-full relative">
                                    <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Nom</label>
                                    <input type="text" v-model="client.nom" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                                </div>
                                <div class="my-[10px] md:w-[48%] w-full relative">
                                    <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Prénoms</label>
                                    <input type="text" v-model="client.prenoms" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                                </div>
                            </div>
                            <div class="my-[10px] w-full relative">
                                <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Adresse</label>
                                <input type="text" v-model="client.adresse" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                            </div>
                            <div class="w-full relative flex justify-between items-center md:flex-row flex-col">
                                <div class="my-[10px] md:w-[48%] w-full relative">
                                    <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Numéro</label>
                                    <input type="text" v-model="client.numero" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                                </div>
                                <div class="my-[10px] md:w-[48%] w-full relative">
                                    <label for="" class="text-sm mb-[5px] font-bold text-gray-600">Email</label>
                                    <input type="text" v-model="client.email" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                                </div>
                            </div>
                        </div>
                        <div class="w-full relative flex flex-col py-[20px] border-b-[0.5px] border-gray-400">
                            <h1 class="text-gray-400 font-bold mb-[20px] md:text-lg text-sm">
                                Moyen de paiement
                            </h1>
                            <div class="w-full flex justify-between items-center flex-wrap relative">
                                <div class="flex items-center my-[10px]">
                                    <input type="radio" name="paiement" value="Espece" id="espèce" v-model="selectedPaymentMethod">
                                    <label for="espèce" class="mx-[5px] text-sm font-semibold text-gray-700">En espèce</label>
                                </div>
                                <div class="flex items-center my-[10px]">
                                    <input type="radio" name="paiement" value="Mobile" id="mobile" v-model="selectedPaymentMethod">
                                    <label for="mobile" class="mx-[5px] text-sm font-semibold text-gray-700">Paiement mobile</label>
                                </div>
                                <div class="flex items-center my-[10px]">
                                    <input type="radio" name="paiement" value="carte" id="carte" v-model="selectedPaymentMethod">
                                    <label for="carte" class="mx-[5px] text-sm font-semibold text-gray-700">Paiement par carte</label>
                                </div>
                            </div>
                            <div v-if="selectedPaymentMethod === 'Mobile' || selectedPaymentMethod === 'carte'" class="my-[10px] w-full relative">
                                <label for="numero" class="text-sm mb-[5px] font-bold text-gray-600">Entrez le numéro</label>
                                <input type="text" id="numero" class="w-full p-[10px] text-sm rounded-[5px] bg-gray-100 outline-none">
                            </div>
                        </div>
                        <div class="w-full relative flex flex-col py-[20px] border-b-[0.5px] border-gray-400">
                            <div class="flex w-full relative justify-between py-[10px] md:justify-start items-center">
                              <button @click="selecteType('Vente')" class="p-[10px] rounded-[5px] text-sm font-bold md:w-auto w-[48%] min-w-[100px]" :class="{'border border-blue-600 bg-transparent text-blue-600': type !== 'Vente', 'bg-blue-600 text-white border border-blue-600': type === 'Vente'}">Vente</button>
                              <button @click="selecteType('Troc')" class="p-[10px] rounded-[5px] text-sm font-bold md:ml-[20px] md:w-auto w-[48%] min-w-[100px]" :class="{'border border-green-600 bg-transparent text-green-600': type !== 'Troc', 'bg-green-600 text-white border borer-green-600': type === 'Troc'}">Troc</button>
                            </div>
                            <form v-if="type === 'Troc'" @submit.prevent="submitForm()" class="mb-[10px] relative w-full">
                              <div class="w-full flex pt-[10px] justify-center items-center flex-col relative">
                                
                                <div class="w-full flex flex-col relative my-[10px]">
                                  <label for="" class="text-sm font-bold">Nom Lot <span class="text-red-500">*</span></label>
                                  <input type="text"  v-model="nomProduit" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                                </div>
                                <div class="w-full flex flex-col relative my-[10px]">
                                  <label for="" class="text-sm font-bold">Description <span class="text-red-500">*</span></label>
                                  <input type="text"  v-model="descriptionProduit" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                                </div>
                                <div class="w-full flex flex-col relative my-[10px]">
                                  <label for="" class="text-sm font-bold">IMEI 1 <span class="text-red-500">*</span></label>
                                  <input type="text" v-model="imei1" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                                </div>
                                <div class="w-full flex flex-col relative my-[10px]">
                                  <label for="" class="text-sm font-bold">IMEI 2 <span class="text-red-500">*</span></label>
                                  <input type="text" v-model="imei2" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                                </div>
                                <div class="w-full flex flex-col relative my-[10px]">
                                  <label for="" class="text-sm font-bold">Stock <span class="text-red-500">*</span></label>
                                  <select name="stock" id="" v-model="stock" required class="p-[10px] w-full rounded-[5px] outline-none border text-sm font-semibold text-gray-600 border-gray-400">
                                    <!-- Si l'utilisateur est Super, afficher toutes les options -->
                                    <option v-if="user.isSuper" value="Stock Parakou">Stock Parakou</option>
                                    <option v-if="user.isSuper" value="Stock Cotonou">Stock Cotonou</option>
                                    <option v-if="user.isSuper" value="Magasin Parakou">Magasin Parakou</option>
                                    <option v-if="user.isSuper" value="Magasin Cotonou">Magasin Cotonou</option>
                                  
                                    <!-- Sinon, afficher seulement l'option correspondant à la boutique de l'utilisateur -->
                                    <option v-else-if="user.boutique == 'Parakou'" value="Stock Parakou">Stock Parakou</option>
                                    <option v-else-if="user.boutique == 'Cotonou'" value="Stock Cotonou">Stock Cotonou</option>
                                  </select>
                                
                                </div>
                                <div class="w-full flex flex-col relative my-[10px]">
                                  <label for="" class="text-sm font-bold">Estimation du Prix <span class="text-red-500">*</span></label>
                                  <input type="text" v-model.number="prix" required class="p-[10px] w-full rounded-[5px] outline-none border border-gray-400">
                                  <span class="absolute right-[10px] text-sm font-bold top-[30px]"> FCFA</span>
                                </div>
                                <div class="w-full flex flex-col relative my-[10px]">
                                  <button type="submit" :disabled="isLoading" class="text-center w-full p-[10px] h-[40px] text-white font-bold bg-gray-800 text-sm flex justify-center items-center">
                                      <span v-if="!isLoading ">Ajouter le nouveau produit</span>
                                      <div v-if="isLoading" class="spinner">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                  </button>
                                </div>
                              </div>
                            </form>
                        </div>
                    </div>
                    <div class="lg:w-4/12 w-full lg:ml-[10px] mt-[10px] lg:mt-0 bg-white relative shadow-4xl rounded-t-[10px] pb-[30px] p-[10px]">
                        <div v-if="produits.length !== 0 && information == true" class="py-[10px] w-full relative border-b-[0.5px] border-gray-400">
                            <div v-for="(produit, index) in produits" :key="index" class="w-full relative py-[10px] flex justify-between items-center">
                                <div class="flex items-center">
                                    <div class="w-[30px] h-[30px] flex justify-center shadow-6xl items-center relative">
                                        <img :src="produit.lotPhoto" alt="" class="w-full h-full absolute object-cover">
                                    </div>
                                    <h1 class="ml-[5px] text-sm font-semibold text-gray-600">{{ produit.lotName }} <span class="text-[8px] mt-[-10px]">X</span><span class="text-gray-800 font-bold">{{ produit.quantity }}</span></h1>
                                </div>
                                <h1 class="text-xs font-bold text-gray-500">
                                    {{ produit.stotal }}
                                </h1>
                            </div>
                        </div>
                        <div class="w-full my-[15px] flex justify-between items-center" v-for="(categorie, index) in categories" :key="index">
                            <h1 class="text-gray-500 text-sm font-bold">{{ categorie.nom }}</h1>
                            <span class="text-xs font-bold text-gray-800">{{ categorie.total }} FCFA</span>
                        </div>
                        <div class=" my-[20px] border-t-[0.5px] border-gray-400 py-[10px]">
                            <div class="w-full flex justify-between items-center my-[10px]">
                                <h1 class="text-gray-800 text-sm font-bold">Réduction</h1>
                                <span class="text-xs font-bold text-gray-800">{{ reductionPourcentage }} %</span>
                            </div>
                            <div class="w-full flex justify-between items-center my-[10px]">
                                <h1 class="text-gray-800 text-sm font-bold">Total</h1>
                                <span class="text-xs font-bold text-gray-800">{{ totalApresReduction }} FCFA</span>
                            </div>
                            <div class="w-full flex justify-between items-center my-[10px]">
                                <input v-model="reductionMontant" @input="calculerReduction" type="text" placeholder="Montant de la réduction" class="w-full p-[10px] rounded-[5px] text-sm bg-gray-100 outline-none">
                            </div>
                        </div>
                        <div class="text-center w-full absolute bottom-0 left-0 flex justify-center items-center ">
                            <button @click="toggleMenu" class="text-center w-full p-[10px] text-white font-bold text-sm flex justify-center h-[40px] items-center" :class="{'mr-[10px] bg-gray-800': information && !achat, 'bg-gray-600': !information && achat}">
                                <span v-if="!information && achat">Valider la commande</span>
                                <span v-if="information && !achat">Retour</span>
                            </button>
                            <button @click="createVente()" :disabled="isLoading" v-if="information && !achat" class="text-center w-full p-[10px] h-[40px] text-white font-bold bg-orange-600 text-sm flex justify-center items-center" :class="{'ml-[10px]': information && !achat}">
                                <span v-if="!isLoading ">Confirmer</span>
                                <div v-if="isLoading" class="spinner">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isDone" class="lg:w-[calc(100vw-280px)] w-full h-[calc(100vh-70px)] right-0 bg-gray-100 fixed bottom-0 top-[70px] z-10 flex justify-center items-center flex-col">
                <h1 class="text-gray-600 font-bold text-xl">Tout est fait !</h1>
                <img src="../assets/images/all-done.png" alt="" class="h-[150px] my-[20px]">
                <p class="w-full px-[20px] max-w-[600px] text-center text-sm font-semibold text-gray-500">
                    La commande a été enregistrée avec succès en votre nom et la facture a été envoyé au client! Vous trouverez dans l'onglet Historique le résumé de la vente.
                </p>
                <button @click="reload()" class="text-white font-bold text-sm bg-gray-800/80 rounded-[5px] p-[10px] mt-[20px]"> Revenir à la Page de vente</button>
            </div>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from "../components/HeaderComponent.vue"
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const troc = ref({});
const isDone = ref(false)

function reload() {
    window.location.reload()
}

const nomuseLot = ref('')
const isVente = ref(true)
const isTroc = ref(false)
const type = ref('Vente')

const selecteType = (typeText) => {
  type.value = typeText
}

function toggleVente() {
  isVente.value = true,
  isTroc.value = false
}
function toggleTroc() {
  isVente.value = false,
  isTroc.value = true
}

const nomProduit = ref('')
const descriptionProduit = ref('')
const isLoading = ref(false)
const information = ref(false)
const achat = ref(true)
const afficherProduits = ref(false)
const selectedPaymentMethod = ref('');
const fullname = ref('')
fullname.value = user.value.firstname + ' ' + user.value.lastname
function toggleMenu() {
    information.value = !information.value
    achat.value = !achat.value
}
const client = ref({
  nom: '',
  prenoms: '',
  adresse: '',
  email: '',
  numero: ''
})
const totalnet = ref(0)

const submitForm = async () => {
  try {
    // Envoyer les données mises à jour à l'API
    const response = await axios.post(`/api/lots-produit`,
    {
      "name": nomProduit.value,
      "description": descriptionProduit.value,
      "categorie": "Apple",
      "seuil": 1,
      "produits": [
        {
          "imei1": imei1.value,
          "imei2": imei2.value,
          "stock": stock.value,
          "quantite": 1,
          "prix": prix.value,
          "capacities": ["Description"],
          "colors": ["Description"],
          "prix_reduit": prix.value
        }
      ]
    }
  
  );

    const lot = response.data.lot;
    const produit = lot.produits[0]; 

    troc.value = {
      lotName: lot.name || '', 
      lotCategorie: lot.categorie || '', 
      imei1: produit.imei1 || '', 
      imei2: produit.imei2 || '', 
      stock: produit.stock || '',
      capacities: produit.capacities || [], 
      colors: produit.colors || [], 
      prix: produit.prix || 0, 
      prix_reduit: produit.prix_reduit || 0,
      quantity: produit.quantite || 0,
      stotal: produit.prix_reduit ? produit.prix_reduit * produit.quantite : produit.prix * produit.quantite // Calculer le sous-total
    };

    quantite.value = '';
    stock.value = '';
    prix.value = '';
    prix_reduit.value = '';
    imei1.value = '';
    imei2.value = '';
    capacities.value = [];
    colors.value = [];
  } catch (error) {
    console.log('Erreur lors de l\'ajout du produit');
  }
};

const createVente = async () => {
  isLoading.value = true
  try {
    const response = await axios.post('/api/sales', {
      produits: produits.value,
      client: client.value,
      troc: troc.value,
      vendeur: {
        nom: user.value.lastname,
        prenoms: user.value.firstname,
        email: user.value.email,
        numero: user.value.phone,
        boutique: user.value.boutique,
        poste: user.value.poste
      },
      type: type.value,
      total: Number(totalApresReduction.value) + Number(reductionMontant.value),
      reductionMontant: reductionMontant.value,
      totalApresReduction: totalApresReduction.value,
      moyenDePaiement: selectedPaymentMethod.value,
    })
    isDone.value = true
    produits.value = []
  } catch (error) {
    errorMessage.value = 'Erreur lors de la validation de la vente'
    hideErrorMessageAfterDelay()
  } finally {
    isLoading.value = false 
  }
}


const errorMessage = ref('')
const successMessage = ref('')
const tousLesProduits = ref([])
const lots = ref([])
const fetchLots = async () => {
  try {
    const response = await axios.get('/api/lots');
    lots.value = response.data
    tousLesProduits.value = lots.value;
    filteredLot.value = lots.value
  } catch (error) {
    errorMessage.value = 'Erreur lors du chargement des produits.';
    hideErrorMessageAfterDelay()
  }
};

const imei1 = ref('')
const imei2 = ref('')
const etat = ref('')
const nom = ref('');
const categori = ref('');
const quantite = ref('');
const description = ref('');
const stock = ref('');
const prix_reduit = ref(0);
const seuil = ref(0)

// Variables pour la capacité et la couleur
/* const newCapacity = ref('');
const capacities = ref([]);
const newColor = ref('');
const colors = ref([]);

// Ajouter la capacité à la liste
function addCapacity() {
  if (newCapacity.value) {
    capacities.value.push(newCapacity.value);
    newCapacity.value = '';
  }
}

// Supprimer une capacité de la liste
function removeCapacity(index) {
  capacities.value.splice(index, 1);
}

// Ajouter la couleur à la liste
function addColor() {
  if (newColor.value) {
    colors.value.push(newColor.value);
    newColor.value = '';
  }
}

// Supprimer une couleur de la liste
function removeColor(index) {
  colors.value.splice(index, 1);
}
 */
const selectedImages = ref([]);

const fileInput = ref(null);

const ajouteArticle = ref(false)

function ajouterArticle() {
    ajouteArticle.value = true
    modifieArticle.value = false
}
function fermerAjout() {
    ajouteArticle.value = false
}

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileChange = (event) => {
  const files = event.target.files;
  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    reader.onload = (e) => {
      selectedImages.value.push(e.target.result);
    };
    reader.readAsDataURL(files[i]);
  }
};

const removeImage = (index) => {
  selectedImages.value.splice(index, 1);
};

const suggestionsLot = ref([])
const filteredLot = ref([])
const selectedLotId = ref(null);
const selectedLotCategori = ref('')

const suggestions = (inputName) => {
    const input = nomuseLot.value;
    
    // Filtrer les lots par nom et par catégorie
    const suggestions = lots.value.filter(p => 
        p.name.toLowerCase().includes(input.toLowerCase()) && 
        categoriesT.value.some(categorie => categorie.name === p.categorie) &&
        p.name.toLowerCase().includes('occasion')
    );
    
    if (inputName === 'nomuseLot') {
        suggestionsLot.value = suggestions;
    }
};

const invalidCount = (lot) => {
  return lot.produits.filter(produit => !produit.isValide).length;
};


const selectLot = (lot) => {
  nomuseLot.value = lot.name;
  selectedLotId.value = lot._id;
  selectedLotCategori.value = lot.categorie 
  suggestionsLot.value = [];
};

const prix = ref(null);



const categoriesT = ref([])

const setCategories = (poste) => {
  const allCategories = {
    'Manager': [
      {
          'name': 'Apple',
          'icone': 'M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25', 
      }, 
      {
          'name': 'Samsung',
          'icone': 'm19.817 10.28l.046 2.694h-.023l-.78-2.693h-1.283v3.392h.848l-.046-2.785h.023l.836 2.785h1.227v-3.392zm-16.15 0l-.641 3.428h.928l.47-3.118h.023l.459 3.118h.916l-.63-3.427zm5.181 0l-.424 2.614h-.023l-.424-2.613H6.58l-.069 3.427h.86l.023-3.083h.011l.573 3.083h.871l.573-3.083h.023l.023 3.083h.86l-.08-3.427zm-7.266 2.454a.5.5 0 0 1 .011.252c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332H0v.264c0 .768.607.997 1.25.997c.618 0 1.134-.218 1.214-.78c.046-.298.012-.492 0-.561c-.16-.722-1.467-.929-1.559-1.33a.5.5 0 0 1 0-.183c.023-.115.104-.23.31-.23s.32.127.32.31v.206h.86v-.24c0-.745-.676-.86-1.157-.86c-.608 0-1.112.206-1.204.757a1.04 1.04 0 0 0 .012.458c.137.71 1.364.917 1.536 1.352m11.152 0c.034.08.022.184.011.253c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332h-.917v.264c0 .756.596.985 1.238.985c.619 0 1.123-.206 1.203-.779c.046-.298.012-.481 0-.562c-.137-.71-1.433-.928-1.524-1.318a.5.5 0 0 1 0-.183c.023-.115.103-.23.31-.23c.194 0 .32.127.32.31v.206h.848v-.24c0-.745-.665-.86-1.146-.86c-.607 0-1.1.195-1.192.757c-.023.149-.023.286.012.458c.137.71 1.34.905 1.513 1.352m2.888.459c.24 0 .31-.16.332-.252c.012-.035.012-.092.012-.126V10.28h.87v2.464c0 .069 0 .195-.01.23c-.058.641-.562.847-1.193.847c-.63 0-1.134-.206-1.192-.848c0-.034-.011-.16-.011-.229V10.28h.87v2.533c0 .046 0 .091.012.126c0 .091.07.252.31.252m7.152-.034c.252 0 .332-.16.355-.253c.011-.034.011-.091.011-.126v-.493h-.355v-.504H24v.917c0 .069 0 .115-.011.23c-.058.63-.597.847-1.204.847s-1.146-.217-1.203-.848c-.012-.114-.012-.16-.012-.229v-1.444c0-.057.012-.172.012-.23c.08-.641.596-.847 1.203-.847s1.135.206 1.203.848c.012.103.012.229.012.229v.115h-.86v-.195s0-.08-.011-.126c-.012-.08-.08-.252-.344-.252c-.252 0-.32.16-.344.252c-.011.045-.011.103-.011.16v1.57c0 .046 0 .092.011.126c0 .092.092.253.333.253', 
      }, 
      {
          'name': 'Accessoires',
          'icone': 'M1.5 8.5V13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8.5M8 8.5v5M1.5 10H8M.5 4L2 .5h10L13.5 4H.5zm4.25 0v1a2 2 0 0 1-2 2h-.28a2 2 0 0 1-2-2V4m8.78 0v1a2 2 0 0 1-2 2h-.5a2 2 0 0 1-2-2V4m8.75 0v1a2 2 0 0 1-2 2h-.25a2 2 0 0 1-2-2V4'
      }, 
    ],
    'Vendeur(se) Accessoires et Téléphone': [
      {
          'name': 'Apple',
          'icone': 'M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25', 
      }, 
      {
          'name': 'Samsung',
          'icone': 'm19.817 10.28l.046 2.694h-.023l-.78-2.693h-1.283v3.392h.848l-.046-2.785h.023l.836 2.785h1.227v-3.392zm-16.15 0l-.641 3.428h.928l.47-3.118h.023l.459 3.118h.916l-.63-3.427zm5.181 0l-.424 2.614h-.023l-.424-2.613H6.58l-.069 3.427h.86l.023-3.083h.011l.573 3.083h.871l.573-3.083h.023l.023 3.083h.86l-.08-3.427zm-7.266 2.454a.5.5 0 0 1 .011.252c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332H0v.264c0 .768.607.997 1.25.997c.618 0 1.134-.218 1.214-.78c.046-.298.012-.492 0-.561c-.16-.722-1.467-.929-1.559-1.33a.5.5 0 0 1 0-.183c.023-.115.104-.23.31-.23s.32.127.32.31v.206h.86v-.24c0-.745-.676-.86-1.157-.86c-.608 0-1.112.206-1.204.757a1.04 1.04 0 0 0 .012.458c.137.71 1.364.917 1.536 1.352m11.152 0c.034.08.022.184.011.253c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332h-.917v.264c0 .756.596.985 1.238.985c.619 0 1.123-.206 1.203-.779c.046-.298.012-.481 0-.562c-.137-.71-1.433-.928-1.524-1.318a.5.5 0 0 1 0-.183c.023-.115.103-.23.31-.23c.194 0 .32.127.32.31v.206h.848v-.24c0-.745-.665-.86-1.146-.86c-.607 0-1.1.195-1.192.757c-.023.149-.023.286.012.458c.137.71 1.34.905 1.513 1.352m2.888.459c.24 0 .31-.16.332-.252c.012-.035.012-.092.012-.126V10.28h.87v2.464c0 .069 0 .195-.01.23c-.058.641-.562.847-1.193.847c-.63 0-1.134-.206-1.192-.848c0-.034-.011-.16-.011-.229V10.28h.87v2.533c0 .046 0 .091.012.126c0 .091.07.252.31.252m7.152-.034c.252 0 .332-.16.355-.253c.011-.034.011-.091.011-.126v-.493h-.355v-.504H24v.917c0 .069 0 .115-.011.23c-.058.63-.597.847-1.204.847s-1.146-.217-1.203-.848c-.012-.114-.012-.16-.012-.229v-1.444c0-.057.012-.172.012-.23c.08-.641.596-.847 1.203-.847s1.135.206 1.203.848c.012.103.012.229.012.229v.115h-.86v-.195s0-.08-.011-.126c-.012-.08-.08-.252-.344-.252c-.252 0-.32.16-.344.252c-.011.045-.011.103-.011.16v1.57c0 .046 0 .092.011.126c0 .092.092.253.333.253', 
      }, 
      {
          'name': 'Accessoires',
          'icone': 'M1.5 8.5V13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8.5M8 8.5v5M1.5 10H8M.5 4L2 .5h10L13.5 4H.5zm4.25 0v1a2 2 0 0 1-2 2h-.28a2 2 0 0 1-2-2V4m8.78 0v1a2 2 0 0 1-2 2h-.5a2 2 0 0 1-2-2V4m8.75 0v1a2 2 0 0 1-2 2h-.25a2 2 0 0 1-2-2V4'
      }, 
    ],
    'Vendeur(se) Téléphone': [
      {
          'name': 'Apple',
          'icone': 'M17.05 20.28c-.98.95-2.05.8-3.08.35c-1.09-.46-2.09-.48-3.24 0c-1.44.62-2.2.44-3.06-.35C2.79 15.25 3.51 7.59 9.05 7.31c1.35.07 2.29.74 3.08.8c1.18-.24 2.31-.93 3.57-.84c1.51.12 2.65.72 3.4 1.8c-3.12 1.87-2.38 5.98.48 7.13c-.57 1.5-1.31 2.99-2.54 4.09zM12.03 7.25c-.15-2.23 1.66-4.07 3.74-4.25c.29 2.58-2.34 4.5-3.74 4.25', 
      }, 
      {
          'name': 'Samsung',
          'icone': 'm19.817 10.28l.046 2.694h-.023l-.78-2.693h-1.283v3.392h.848l-.046-2.785h.023l.836 2.785h1.227v-3.392zm-16.15 0l-.641 3.428h.928l.47-3.118h.023l.459 3.118h.916l-.63-3.427zm5.181 0l-.424 2.614h-.023l-.424-2.613H6.58l-.069 3.427h.86l.023-3.083h.011l.573 3.083h.871l.573-3.083h.023l.023 3.083h.86l-.08-3.427zm-7.266 2.454a.5.5 0 0 1 .011.252c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332H0v.264c0 .768.607.997 1.25.997c.618 0 1.134-.218 1.214-.78c.046-.298.012-.492 0-.561c-.16-.722-1.467-.929-1.559-1.33a.5.5 0 0 1 0-.183c.023-.115.104-.23.31-.23s.32.127.32.31v.206h.86v-.24c0-.745-.676-.86-1.157-.86c-.608 0-1.112.206-1.204.757a1.04 1.04 0 0 0 .012.458c.137.71 1.364.917 1.536 1.352m11.152 0c.034.08.022.184.011.253c-.023.114-.103.229-.332.229c-.218 0-.344-.126-.344-.31v-.332h-.917v.264c0 .756.596.985 1.238.985c.619 0 1.123-.206 1.203-.779c.046-.298.012-.481 0-.562c-.137-.71-1.433-.928-1.524-1.318a.5.5 0 0 1 0-.183c.023-.115.103-.23.31-.23c.194 0 .32.127.32.31v.206h.848v-.24c0-.745-.665-.86-1.146-.86c-.607 0-1.1.195-1.192.757c-.023.149-.023.286.012.458c.137.71 1.34.905 1.513 1.352m2.888.459c.24 0 .31-.16.332-.252c.012-.035.012-.092.012-.126V10.28h.87v2.464c0 .069 0 .195-.01.23c-.058.641-.562.847-1.193.847c-.63 0-1.134-.206-1.192-.848c0-.034-.011-.16-.011-.229V10.28h.87v2.533c0 .046 0 .091.012.126c0 .091.07.252.31.252m7.152-.034c.252 0 .332-.16.355-.253c.011-.034.011-.091.011-.126v-.493h-.355v-.504H24v.917c0 .069 0 .115-.011.23c-.058.63-.597.847-1.204.847s-1.146-.217-1.203-.848c-.012-.114-.012-.16-.012-.229v-1.444c0-.057.012-.172.012-.23c.08-.641.596-.847 1.203-.847s1.135.206 1.203.848c.012.103.012.229.012.229v.115h-.86v-.195s0-.08-.011-.126c-.012-.08-.08-.252-.344-.252c-.252 0-.32.16-.344.252c-.011.045-.011.103-.011.16v1.57c0 .046 0 .092.011.126c0 .092.092.253.333.253', 
      },
    ],
    'Vendeur(se) Accessoires': [
      {
          'name': 'Accessoires',
          'icone': 'M1.5 8.5V13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8.5M8 8.5v5M1.5 10H8M.5 4L2 .5h10L13.5 4H.5zm4.25 0v1a2 2 0 0 1-2 2h-.28a2 2 0 0 1-2-2V4m8.78 0v1a2 2 0 0 1-2 2h-.5a2 2 0 0 1-2-2V4m8.75 0v1a2 2 0 0 1-2 2h-.25a2 2 0 0 1-2-2V4'
      },
    ]
  };
  categoriesT.value = allCategories[poste] || [];
};

const selectedCategory = ref(null);


const produits = ref([])

const produitsParLot = computed(() => {
  if (!lots.value || !Array.isArray(lots.value)) return [];

  return lots.value.flatMap(lot => {
    const produits = lot.produits || [];
    const lotName = lot.name || 'Nom de lot inconnu';
    const lotCategori = lot.categorie;
    const lotPhoto = Array.isArray(lot.photos) && lot.photos.length > 0 ? lot.photos[0] : './img/logo.jpg';

    return produits.map(produit => ({
      ...produit,
      lotName,
      lotCategori,
      photos: Array.isArray(produit.photos) && produit.photos.length > 0 ? produit.photos[0] : lotPhoto,
    }));
  });
});

const recherche = ref("")
const reductionMontant = ref(0)

const produitsFiltres = computed(() => {
  const searchQuery = recherche.value.toLowerCase().trim();

  return produitsParLot.value.filter(produit => {
    if (!produit.isValide) {
      return false;
    }
    const categorieMatch = categoriesT.value.some(categorie => categorie.name === produit.lotCategori);
    if (!categorieMatch) {
      return false;
    }

    if ( user.value.isSuper ) {
      return true
    } else {
      if (produit.stock !== 'Stock ' + user.value.boutique) {
        return false;
      }
    }

    const nameMatch = produit.lotName && produit.lotName.toLowerCase().includes(searchQuery);
    const imei1Match = produit.imei1 && produit.imei1.toLowerCase().includes(searchQuery);
    const imei2Match = produit.imei2 && produit.imei2.toLowerCase().includes(searchQuery);
    const etatMatch = produit.etat && produit.etat.toLowerCase().includes(searchQuery);
    const capacitiesMatch = produit.capacities && produit.capacities.some(capacity => capacity.toLowerCase().includes(searchQuery));
    
    const quantityValid = produit.quantite > 0;

    return (nameMatch || imei1Match || imei2Match || etatMatch || capacitiesMatch) && quantityValid;
  });
});


function afficherListeProduits() {
    afficherProduits.value = !afficherProduits.value
}

function ajouterProduit(produit) {
    const produitExistant = produits.value.find(p => p._id === produit._id)
    if (produitExistant) {
        produitExistant.quantity++
        produitExistant.stotal = produitExistant.quantity * produitExistant.prix_reduit
    } else {
        produits.value.push({
            ...produit,
            quantity: 1,
            stotal: produit.prix_reduit
        })
    }
    afficherProduits.value = false
}

function augmenterQuantite(index) {
    const produit = produits.value[index];

    // Vérifier si la quantité en stock est suffisante pour augmenter
    if (produit.quantity < produit.quantite) {
        produit.quantity++;
        produit.stotal = produit.quantity * produit.prix_reduit;
    } else {
        // Afficher un message d'erreur ou une notification
        alert('Quantité insuffisante en stock.');
    }
}


function diminuerQuantite(index) {
    if (produits.value[index].quantity > 1) {
        produits.value[index].quantity--
        produits.value[index].stotal = produits.value[index].quantity * produits.value[index].prix_reduit
    } else {
        produits.value.splice(index, 1)
    }
}

const total = computed(() => {
    return produits.value.reduce((acc, produit) => acc + produit.stotal, 0)
})

const totalApresReduction = computed(() => {
    return total.value - reductionMontant.value
})

const categories = computed(() => {
    const categoriesExistantes = ['Apple', 'Samsung', 'Accessoires']
    const result = categoriesExistantes.map(categorie => {
        const produitsCategorie = produits.value.filter(p => p.lotCategori === categorie)
        const totalCategorie = produitsCategorie.reduce((acc, produit) => acc + produit.stotal, 0)
        return {
            nom: categorie,
            total: totalCategorie
        }
    })
    return result
})

const reductionPourcentage = computed(() => {
    if (total.value === 0) return 0
    return ((reductionMontant.value / total.value) * 100).toFixed(2)
})

function calculerReduction() {
    if (reductionMontant.value < 0) {
        reductionMontant.value = 0
    }
}


const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 5000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 10000);
};


onMounted(() => {
    fetchLots()
    setCategories(user.value.poste)
})
</script>

<style scoped>
</style>
